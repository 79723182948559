import React from "react";
import Layout from "../layouts/Layout";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import makeUrl from "../utils/url";
import SiteMetadata from "../components/SiteMetadata";
import HeadingTop from "../components/BuildingBlocks/HeadingTop";
import PersonList from "../components/PersonList";

function Anlass({ data, items }) {
  const withDate = data.events.nodes.filter((i) => i.date);

  return (
    <Layout>
      <SiteMetadata
        title="Handverlesene Geschenkideen für jeden Anlass"
        description="Lass dich inspirieren von vielen tollen Geschenkideen zum Geburtstag, Valentinstag, Weihnachten und viel mehr!"
      />

      <HeadingTop
        title="Geschenkideen für jeden Anlass"
        subtitle="Alle Events im Überblick."
      />

      <div className="section flex flex-col mb-8 mt-32">
        <div className="mx-auto">
          {withDate.map((item, i, a) => (
            <div key={i} className="flex items-start">
              <div className="w-0.5 h-32 bg-gray-400 relative">
                {i === 0 && (
                  <div className="bg-gradient-to-b from-white to-gray-300 w-0.5 h-32 absolute rounded-full left-1/2 transform -translate-x-1/2 -translate-y-1" />
                )}
                {i === a.length - 1 && (
                  <div className="bg-gradient-to-b from-gray-300 to-white w-0.5 h-32 absolute rounded-full left-1/2 transform -translate-x-1/2" />
                )}
                <div className="bg-gray-400 w-5 h-5 absolute rounded-full left-1/2 transform -translate-x-1/2 border-4 border-white" />
              </div>

              <div className="md:flex -mt-0.5">
                <div className="text-base w-40 mx-4 h-6 -mt-0.5 md:mt-0">
                  {item.date}
                </div>
                <EventItem item={item} className={"md:-translate-y-1/2"} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <PersonList />
    </Layout>
  );
}

const EventItem = ({ item, className }) => (
  <Link to={`/${makeUrl(item.title, "anlass")}`}>
    <div
      className={`flex items-center p-4 bg-gray-100 w-72 rounded-full transform md:mt-3 hover:bg-1-light transition ${className}`}
    >
      <div className="w-16 h-16 mx-2">
        <GatsbyImage
          image={item?.image.localFile.childImageSharp.gatsbyImageData}
          className="h-full w-full rounded-full"
          alt={item?.title}
        />
      </div>

      <div className="font-display text-2xl">{item?.title}</div>
    </div>
  </Link>
);

export default Anlass;

export const query = graphql`
  query AnlassQueryMain {
    events: allContentfulAnlass(sort: { order: ASC, fields: date }) {
      nodes {
        date(locale: "de", formatString: "DD. MMMM. YYYY")
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        title
      }
    }
  }
`;
